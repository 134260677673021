export const SITEMETA_DATA = {
    "home" : {
        title: "Global Payment Solutions",
        description: "The APEXX payment gateway consolidates global payment providers into a single integration point, optimising the cost of complex payment ecosystems."
    },
    "gateway" : {
        title: "E-commerce Payment Gateway",
        description: "The APEXX Gateway is a connection hub between the merchant & their provider, helping to maximise the transactional acceptance rate & avoid foreign exchange fees"
    },
    "surcharge-inspector" : {
        title: "Card Payments Surcharge",
        description: "The APEXX Surcharge Inspector tool allows merchants to inspect individual processed transactions and apply allowed surcharge-able amounts. Find out more here!"
    },
    "analytics" : {
        title: "Global Payments Analytics Dashboard",
        description: "This APEXX Analytics tool provides you with the insights you need for your business by easily rendering billions of transactions in a comprehensible format."
    },
    "apm" : {
        title: "Alternative Payment Methods (APMs)",
        description: "APEXX's integration of Alternative Payment Methods (APMs) is designed to give a smooth and complete experience to the consumer using all of their preferred payment methods."
    },
    "dynamic-routing" : {
        title: "Intelligent Routing Engine (IRE)",
        description: "APEXX's Intelligent Routing Engine (IRE) is a powerful engine designed to improve transaction success rates and reduce processing fees for both merchants and acquirers."
    },
    "partnerships" : {
        title: "Partnerships",
        description: "At APEXX Global we collaborate with our enterprise partners to align with our merchant centric approach. Find out here about our Channel Partner Network!"
    },
    "country-reports" : {
        title: "International Payments System",
        description: "We specialise in guiding merchants into new markets by offering a truly global coverage to local processors, helping maximise benefits and minimise fees. "
    },
    "country-report" : {
        title: "Truly Global Processing Locally",
        description: "Doing business in new markets is always fraught with complications from fulfilment to logistics to legal obstacles etc. APEXX specialises in guiding enterprise merchants into new markets."
    },
    "blog" : {
        title: "Blog",
        description: "Dive into the world of payment technology, explore insights and stay up to date with product and company news."
    },
    "news" : {
        title: "News",
        description: "Explore our latest press releases and news mentions here!"
    },
    "about-us" : {
        title: "About Us",
        description: "We strive to be the payment industry's most merchant centric provider. Find out more about our vision and our team members here!"
    },
    "careers" : {
        title: "Careers",
        description: "We're an ambitious, dynamic global team living for innovation. Explore our opportunities here and join APEXX to make an impact with us!"
    },
    "contact-us" : {
        title: "Contact Us",
        description: "Contact us if you have any queries. We're here to help whenever you need!"
    },
    "what-is-payment-orchestration": {
        title:"How does a Payment Orchestration Platform work?",
        description:"The goal of any payment solution is a simple one: maximise conversion rates and limiting costs. Find out more here."
    },
    "payment-orchestration-platform": {
        title:"Payment Orchestration Platform",
        description:"Designed for enterprise merchants to fully optimise their payments stack by integrating them to all of the most beneficial components of their payment's ecosystem."
    },
    "privacy-policy": {
        title:"Privacy Policy",
        description:"Access the APEXX Global privacy and cookie policy here."
    },
    "terms-conditions": {
        title:"Terms & Conditions",
        description:"Access the APEXX Global terms and conditions here."
    },
    "bnpl-connect" : {
        title: "BNPL Aggregator",
        description: "Drive up conversion rates and basket values and improve your consumers' experience across your global footprint without the need for local entities. Learn more about APEXX: Connect"
    },
    "products-and-services": {
        title:"Products and Services",
        description:"This page sets out the services that may be made available to APEXX merchants."
    },
    "apexx-calculator" : {
        title: "Maximize Savings with our Payment Processing Cost Saving Calculator",
        description: "Discover how much you can save on payment processing by using Payment Orchestration with the easy-to-use APEXX Global Payment Orchestration Cost Saving Calculator. Get your free personalised payment optimisation report on potential savings today."
    },

};